import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { accessToken, refreshToken } from '@/utils/constants';
// eslint-disable-next-line import/no-cycle
import { destroyTokens, saveTokens } from '@/utils/helpers';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;

const access_token = localStorage.getItem(accessToken);
if (access_token) {
  axios.defaults.headers.common.Authorization = access_token;
}

const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      const refresh_token = localStorage.getItem(refreshToken);
      if (!refresh_token) {
        destroyTokens();
        // eslint-disable-next-line no-restricted-globals
        location.replace('/auth');
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);

      return axios
        .post('/auth/refresh-bearer', { refresh_token })
        .then(({ data }) => {
          saveTokens(data);
          // eslint-disable-next-line no-param-reassign
          error.response.config.headers.Authorization = data.access_token;
          return axios(error.response.config);
        })
        .catch(() => {
          destroyTokens();
          // eslint-disable-next-line no-restricted-globals
          location.replace('/auth');
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    },
  );
};

axios.interceptors.request.use(
  (config) => ({
    ...config,
    withCredentials: true,
  }),
  (error) => Promise.reject(error),
);

createAxiosResponseInterceptor();

Vue.use(VueAxios, axios);

export default axios;
