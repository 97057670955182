const formatDate = (date) => {
  const timeToSet = new Date(date);
  return new Intl.DateTimeFormat('ru', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(timeToSet);
};

export const tariffPlans = [
  {
    id: 0,
    name: 'label.tariffFree',
    price: 0.0,
  },
  {
    id: 1,
    name: 'label.tariff50',
    price: 3270.0,
  },
  {
    id: 2,
    name: 'label.tariff100',
    price: 6510.0,
  },
  {
    id: 3,
    name: 'label.tariff200',
    price: 13020.0,
  },
  {
    id: 4,
    name: 'label.tariff300',
    price: 19530.0,
  },
  {
    id: 5,
    name: 'label.tariff50',
    price: 39240.0,
  },
  {
    id: 6,
    name: 'label.tariff100',
    price: 78120.0,
  },
  {
    id: 7,
    name: 'label.tariff200',
    price: 156240.0,
  },
  {
    id: 8,
    name: 'label.tariff300',
    price: 234360.0,
  },
  {
    id: 9,
    name: 'label.tariffProject',
    price: 0.0,
  },
];

// TODO need refactor const tariff -> membership, prolongation
export const tariff = (subscriptionDetail) => {
  // eslint-disable-next-line max-len
  const userTariff = tariffPlans.find((t) => t.id === subscriptionDetail?.membership.id) || tariffPlans[0];
  userTariff.price = subscriptionDetail?.membership?.price;
  // eslint-disable-next-line max-len
  const prolongation = tariffPlans.find((t) => t.id === subscriptionDetail?.prolongation.id) || tariffPlans[0];
  prolongation.price = subscriptionDetail?.prolongation?.price;
  return {
    ...userTariff,
    currentUserAmount: subscriptionDetail?.students_amount || 0,
    expiresAt:
      userTariff.id === 0 ? null : formatDate(subscriptionDetail?.expires_at || new Date()),
    maxUsers: subscriptionDetail?.membership?.students_limit || 0,
    membership: subscriptionDetail?.membership,
    prolongation,
    isAutoProlongation: !!subscriptionDetail?.is_auto_prolongation,
  };
};
