import { accessToken, refreshToken, materialType } from '@/utils/constants';
import findLast from 'lodash/findLast';
// eslint-disable-next-line import/no-cycle
import axios from '@/http/index';
import { ActivityStatuses } from './statusConstants';

const makeFlatArrayFromNestedChildren = (array) => array.reduce((acc, current) => {
  acc.push(current.activity);
  if (current?.children && current.activity.type !== 'quiz') {
    return [...acc, ...makeFlatArrayFromNestedChildren(current.children)];
  }
  return acc;
}, []);

/*
 * Find parent of selected Id
 * @returns Object
 */
export const deepFindActivityParent = (content, id) => {
  if (content?.activity?._id === id) {
    return content.activity;
  }
  if (content?.activity.type === 'course' && content?.children) {
    if (content.children.some((el) => el.activity._id === id)) {
      return content;
    }
  }
  return content?.children?.find((activity) => deepFindActivityParent(activity, id));
};

export const deepFindActivity = (content, id) => {
  if (content?.activity?._id === id) {
    return content.activity;
  }
  return content?.children?.reduce((act, curr) => {
    const found = deepFindActivity(curr, id);
    if (found) {
      return found;
    }
    return act;
  }, null);
};

export const deepFindChildrenByParentActivityId = (content, id) => {
  if (content?.activity?._id === id) {
    return content?.children?.map((i) => i.activity) || [];
  }
  return content?.children?.reduce((arr, curr) => {
    const found = deepFindChildrenByParentActivityId(curr, id);
    if (found?.length) {
      return found;
    }
    return arr;
  }, []);
};

export const lastActiveActivity = (content) => {
  if (!content?.children) {
    return null;
  }

  const foundNotBlockedByStatus = (status, ctx) => {
    let notBlocked;
    if (status !== ActivityStatuses.notStarted) {
      notBlocked = findLast(
        ctx.children,
        (el) => !el.activity.statistic.is_blocked && el.activity.statistic.status === status,
      );
    } else {
      notBlocked = ctx.children.find(
        (el) => !el.activity.statistic.is_blocked && el.activity.statistic.status === status,
      );
    }

    if (!notBlocked) {
      return null;
    }

    if (materialType.includes(notBlocked.activity.type)) {
      return notBlocked;
    }

    if (notBlocked.activity.type === 'group' && !notBlocked?.children) {
      return null;
    }

    if (notBlocked.activity.type === 'group' && notBlocked?.children.length) {
      let found = foundNotBlockedByStatus(ActivityStatuses.doing, notBlocked);
      if (!found) {
        found = foundNotBlockedByStatus(ActivityStatuses.notStarted, notBlocked);
        if (!found) {
          found = foundNotBlockedByStatus(ActivityStatuses.done, notBlocked);
        }
        return found;
      }
      return found;
    }
  };

  let activeActivity = foundNotBlockedByStatus(ActivityStatuses.doing, content);
  if (!activeActivity) {
    activeActivity = foundNotBlockedByStatus(ActivityStatuses.notStarted, content);
    if (!activeActivity) {
      activeActivity = foundNotBlockedByStatus(ActivityStatuses.done, content);
    }
  }

  if (activeActivity) {
    return activeActivity.activity;
  }

  return null;
};

export const saveTokens = (tokens) => {
  localStorage.setItem(accessToken, tokens.access_token);
  localStorage.setItem(refreshToken, tokens.refresh_token);
  axios.defaults.headers.common.Authorization = tokens.access_token;
};

export const destroyTokens = () => {
  localStorage.removeItem(accessToken);
  localStorage.removeItem(refreshToken);
  axios.defaults.headers.common.Authorization = null;
};

export default makeFlatArrayFromNestedChildren;
