export default {
  namespaced: true,

  state: {
    requestQueue: [],
  },

  actions: {
    addToQueue({ commit }, cb) {
      commit('ADD_TO_QUEUE', cb);
    },
    activateQueue({ state, commit }) {
      state.requestQueue.map((cb) => cb());
      commit('RESET_QUEUE');
    },
  },

  mutations: {
    ADD_TO_QUEUE(state, cb) {
      state.requestQueue.push(cb);
    },
    RESET_QUEUE(state) {
      state.requestQueue = [];
    },
  },

  getters: {},
};
