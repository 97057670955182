import StudentService from '@/services/student.service';
import { ActivityStatuses } from '../../../utils/statusConstants';

export default {
  namespaced: true,

  state: {
    courseActivity: null,
    courseContent: [],
    selectedContentIndex: 0,
    loading: false,
  },

  actions: {
    fetchCourseActivity({ commit }, { id, programId }) {
      return StudentService.getCourse({ id, programId }).then(({ data }) => {
        commit('SET_COURSE', data);
      });
    },
    fetchCourseContent({ commit }, { activityId, parentId }) {
      return StudentService.getStudentActivitiesWithParent({
        activityId,
        parentId,
      }).then(({ data }) => {
        commit('SET_COURSE_CONTENT', data);
      });
    },
    setSelectedContentIndex({ commit }, index) {
      commit('SET_SELECTED_CONTENT_INDEX', index);
    },
    setMaterialAsStarted({ commit }, materialId) {
      return StudentService.setActivityStarted(materialId)
        .then(() => commit('SET_MATERIAL_STATISTIC_AS_STARTED', materialId))
        .catch(console.log);
    },
    setMaterialAsCompleted({ commit }, materialId) {
      return StudentService.setActivityCompleted(materialId)
        .then(() => commit('SET_MATERIAL_STATISTIC_AS_COMPLETED', materialId))
        .catch(console.log);
    },
    setSyncMaterialAsCompleted({ commit }, materialId) {
      commit('SET_MATERIAL_STATISTIC_AS_COMPLETED', materialId);
    },
    resetCourseContent({ commit }) {
      commit('RESET_COURSE_CONTENT');
    },
    async setLoading({ commit }, isLoading) {
      commit('SET_LOADING', isLoading);
    },
  },

  mutations: {
    SET_COURSE(state, course) {
      state.courseActivity = course;
    },
    SET_COURSE_CONTENT(state, content) {
      state.courseContent = content;
    },
    SET_SELECTED_CONTENT_INDEX(state, index) {
      state.selectedContentIndex = index;
    },
    SET_MATERIAL_STATISTIC_AS_STARTED(state, materialId) {
      state.courseContent = state.courseContent.map((m) => {
        if (m._id === materialId) {
          const clone = { ...m };
          clone.statistic.status = ActivityStatuses.doing;
          return clone;
        }
        return m;
      });
    },
    SET_MATERIAL_STATISTIC_AS_COMPLETED(state, materialId) {
      state.courseContent = state.courseContent.map((m) => {
        if (m._id === materialId) {
          const clone = { ...m };
          clone.statistic.status = ActivityStatuses.done;
          return clone;
        }
        return m;
      });
    },
    RESET_COURSE_CONTENT(state) {
      state.courseContent = [];
      state.selectedContentIndex = 0;
    },
    SET_LOADING: (state, isLoading) => (state.loading = isLoading),
  },

  getters: {
    getCourseActivity: ({ courseActivity }) => courseActivity,
    getCourseContent: ({ courseContent }) => courseContent,
    getContentIndex: ({ selectedContentIndex }) => selectedContentIndex,
    getSelectedContent: ({ courseContent }, { getContentIndex }) => courseContent[getContentIndex],
    getContentMeta: (_, { getSelectedContent }) => getSelectedContent?.meta,
    getIsLoading: ({ loading }) => loading,
  },
};
