<template>
  <div ref="loader" class="lms-loader">
    <div class="inner-circle"></div>
    <div class="middle-circle"></div>
    <div class="outer-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    disableScale: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const height = this.$el?.parentNode?.getBoundingClientRect().height;
    if (height && !this.disableScale) {
      this.$el.style = `transform: scale(${(height / 156).toFixed(2)});`;
    }
  },
};
</script>
