export default {
  namespaced: true,

  state: {
    pagesCounter: 0,
    currentPage: 1,
    isLoading: true,
  },

  actions: {
    setPagesCount({ commit }, file) {
      commit('SET_PAGES_COUNTER', file.numPages);
    },
    setCurrentPage({ commit }, pageNumber) {
      commit('SET_CURRENT_PAGE', pageNumber);
    },
    setLoading({ commit }, bool) {
      commit('SET_LOADING', bool);
    },
  },

  mutations: {
    SET_PAGES_COUNTER(state, pagesNumber) {
      state.pagesCounter = pagesNumber;
    },
    SET_CURRENT_PAGE(state, pageNumber) {
      state.currentPage = pageNumber;
    },
    SET_LOADING(state, bool) {
      state.isLoading = bool;
    },
  },

  getters: {
    getPagesCounter: ({ pagesCounter }) => pagesCounter,
    getCurrentPage: ({ currentPage }) => currentPage,
  },
};
