import camelCase from 'lodash/camelCase';

const requireModule = require.context('.', true, /\.js$/);
const modules = {};

// eslint-disable-next-line array-callback-return
requireModule.keys().map((f) => {
  if (f === './index.js') return;

  const moduleName = camelCase(f.replace(/(.\/viewer\/|\.\/|\.js)/g, ''));

  modules[moduleName] = requireModule(f).default;
});

export default modules;
