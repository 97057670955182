import Vue from 'vue';

export default Vue.mixin({
  computed: {
    $device() {
      return {
        isMobile: this.$mq === 'sm',
        isTablet: this.$mq === 'md',
        isDesktop: this.$mq === 'xxl',
      };
    },
  },
});
