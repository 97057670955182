/**
 * Decorator only for class fields
 *
 * @param target class prototype
 * @param  key name of the field
 * @param  descriptor object descriptor
 */
const CheckAccess = (target, key, descriptor) => {
  const originalMethod = descriptor.initializer;
  // eslint-disable-next-line no-param-reassign
  descriptor.initializer = () => (...args) => new Promise((resolve, reject) => originalMethod
    .apply(this)(...args)
    .then(resolve)
    .catch((error) => {
      if (error.response) {
        if (error.response?.status === 403) {
          // eslint-disable-next-line no-restricted-globals
          location.replace('/no-access');
        }
        if (error.response?.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.replace('/auth');
        }
      }
      reject(error);
    }));
};
export default CheckAccess;
