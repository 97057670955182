<template>
  <div class="d-flex flex-column h-100 position-relative fix-scroll">
    <MainNavbar />
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue';

export default {
  name: 'Home',
  components: { MainNavbar },
};
</script>
