export default {
  namespaced: true,

  state: {
    toaster: null,
  },

  actions: {
    setToaster({ commit }, toaster) {
      commit('SET_TOASTER', toaster);
    },
  },

  mutations: {
    SET_TOASTER(state, toaster) {
      state.toaster = toaster;
    },
  },

  getters: {
    getToaster: ({ toaster }) => toaster,
  },
};
