export default {
  namespaced: true,

  state: {
    appConfig: null,
  },

  actions: {
    async fetchConfiguration({ commit }) {
      const appConfig = await require('../../../config/app_config.json');
      commit('SET_CONfIGURATION', appConfig);
    },
  },

  mutations: {
    SET_CONfIGURATION(state, appConfig) {
      state.appConfig = appConfig;
    },
  },

  getters: {
    getAppConfig: ({ appConfig }) => appConfig,
  },
};
