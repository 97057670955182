import AuthService from '@/services/auth.service';
import { tariff } from '@/utils/tariff';

export default {
  namespaced: true,

  state: {
    isAuthenticated: false,
    user: null,
    isModeTeacher: true,
  },

  actions: {
    registration(_, { registerInput, locale }) {
      return AuthService.register(registerInput, locale);
    },
    authorization(_, loginInput) {
      return AuthService.login(loginInput);
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, { isAuthenticated, user = null }) {
      state.isAuthenticated = isAuthenticated;
      state.user = user;
    },

    CHANGE_MODE_TEACHER(state) {
      state.isModeTeacher = !state.isModeTeacher;
      localStorage.setItem('isModeTeacher', state.isModeTeacher);
    },
  },

  getters: {
    isAuthenticated: ({ isAuthenticated }) => isAuthenticated,
    isModeTeacher: ({ isModeTeacher }) => isModeTeacher,
    getUser: ({ user }) => user,
    getUserTariff: ({ user }) => tariff(user?.subscription_details),
    isEmailConfirmed: ({ user }) => user.email_confirmed,
    doesHaveActiveTariff: ({ user }) => !!user.subscription_details?.expires_at,
    getEmail: ({ user }) => user?.email ?? '',
    getFirstName: ({ user }) => user?.first_name ?? '',
    getLastName: ({ user }) => user?.last_name ?? '',
    getFullName: ({ user }) => `${user?.last_name || ''} ${user?.first_name || ''}`,
    isTariffEndsSoon: ({ user }) => {
      if (!user?.subscription_details) {
        return false;
      }
      if (user.subscription_details?.expires_at) {
        return false;
      }
      const dayBeforeNeededToBeNotificated = 2;
      const expiresAt = new Date(user.subscription_details?.expires_at);
      expiresAt.setHours(0, 0, 0, 0);
      const isActual = expiresAt > new Date();
      expiresAt.setDate(expiresAt.getDate() - dayBeforeNeededToBeNotificated);
      return new Date() > expiresAt && isActual;
    },
  },
};
