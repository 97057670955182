import axios from '@/http/index';

class MaterialService {
  uploadMaterial = (file) => axios.put('fs/material', file);

  uploadFile = (file) => axios.put('fs/file', file);

  uploadCover = (file) => axios.put('fs/cover', file);
}

export default new MaterialService();
