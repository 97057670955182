import StudentService from '@/services/student.service';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  namespaced: true,

  state: {
    chatList: null,
  },

  actions: {
    fetchChats({ commit }) {
      return StudentService.getChatsList()
        .then(({ data }) => {
          commit('SET_CHATS', data);
        })
        .catch();
    },
    setHomeworkAsCompleted({ commit }, chatId) {
      commit('SET_HOMEWORK_AS_COMPLETED', chatId);
    },
    setHomeworkAsRejected({ commit }, chatId) {
      commit('SET_HOMEWORK_AS_REJECTED', chatId);
    },
  },

  mutations: {
    SET_CHATS(state, data) {
      state.chatList = data;
    },
    SET_HOMEWORK_AS_COMPLETED(state, chatId) {
      state.chatList = state.chatList.map((m) => {
        if (m.TaskChatID === chatId) {
          const clone = { ...m };
          clone.Statistics.status = ActivityStatuses.done;
          if (clone.Statistics.meta) {
            clone.Statistics.meta[0].Value += 1;
          } else {
            clone.Statistics.meta = [{ Value: 1 }];
          }
          return clone;
        }
        return m;
      });
    },
    SET_HOMEWORK_AS_REJECTED(state, chatId) {
      state.chatList = state.chatList.map((m) => {
        if (m.TaskChatID === chatId) {
          const clone = { ...m };
          clone.Statistics.status = ActivityStatuses.rejected;
          if (clone.Statistics.meta) {
            clone.Statistics.meta[0].Value += 1;
          } else {
            clone.Statistics.meta = [{ Value: 1 }];
          }
          return clone;
        }
        return m;
      });
    },
  },

  getters: {
    getChatList: ({ chatList }) => chatList,
    getChatAuthorNameById: (state) => (id) => {
      if (state.chatList?.length) {
        const author = state.chatList.find((item) => item.User._id === id).User;
        return `${author.firstName} ${author.lastName}`;
      }
    },
    getFilteredCourses({ chatList: chats }) {
      const coursesIds = [...new Set(chats.map((ch) => ch.Course._id))];
      return coursesIds.map((id) => {
        const obj = {
          id, courseName: '', tasks: [],
        };

        chats.map((ch) => {
          if (ch.Course._id === id) {
            obj.courseName = ch.Course.name;
            const foundIndex = obj.tasks.findIndex((t) => t._id === ch.Task._id);
            if (foundIndex < 0) {
              obj.tasks.push(ch.Task);
            }
          }
          return ch;
        });
        return obj;
      });
    },
  },
};
