<template>
  <b-alert v-model="showCookies" class="cookies-agreement">
    <img src="@/assets/images/png/cookie.png" alt="Cookies" />
    <i18n path="supportText.weUseCookies" tag="p" class="cookies-agreement__content">
      <a class="cookies-agreement__link" href="https://ru.wikipedia.org/wiki/Cookie" target="_blank"
        >cookies</a
      >
    </i18n>

    <b-button variant="primary" class="cookies-agreement__control small" @click="acceptCookies"
      >{{ $t('buttonLabels.ok') }}
    </b-button>
  </b-alert>
</template>

<script>
export default {
  name: 'CookiesNotification',
  data: () => ({
    showCookies: false,
  }),
  methods: {
    checkCookiesAgreement() {
      if (!localStorage.getItem('cookies-agreement')) {
        this.showCookies = true;
      }
    },

    acceptCookies() {
      localStorage.setItem('cookies-agreement', 'true');
      this.showCookies = false;
    },
  },
  mounted() {
    this.checkCookiesAgreement();
  },
};
</script>
