import ActivityService from '@/services/activity.service';
import makeFlatArrayFromNestedChildren from '@/utils/helpers';

const materialType = ['task', 'material', 'quiz', 'video'];

export default {
  namespaced: true,

  state: {
    courseId: null,
    groupIdOfLevelOne: null,
    groupIdOfLevelTwo: null,
    content: [],
    materials: [],
    selectedContentId: null,
  },

  actions: {
    createActivity({ commit }, payload) {
      const activity = {
        type: payload?.activityType ? payload.activityType : 'material',
        id: payload.parentId,
        meta: payload?.meta || {},
        name: payload?.title || '',
        description: payload?.description || '',
        max_score: payload?.max_score || 0,
      };
      return ActivityService.createActivityByType(activity)
        .then(({ data }) => {
          delete activity.id;
          activity.parent_id = payload.parentId;
          commit('ADD_MATERIAL', { ...activity, _id: data });
          return data;
        })
        .catch(console.log);
    },
    createGroup({ commit }, parent_id) {
      const group = {
        type: 'group',
        id: parent_id,
        name: '',
        description: '',
      };

      return ActivityService.createActivityByType(group)
        .then(({ data: id }) => {
          delete group.id;
          group._id = id;
          group.parent_id = parent_id;

          commit('ADD_GROUP', group);
        })
        .catch(console.log);
    },
    updateActivity({ commit }, { id, activity }) {
      return ActivityService.updateActivity(id, activity)
        .then(({ data }) => {
          commit('UPDATE_ACTIVITY', data);
          return data;
        })
        .catch(console.log);
    },
    changeBlockActivityOrdering({ commit }, { id, blocking }) {
      if (blocking) {
        return ActivityService.blockOrderOfActivity(id)
          .then(() => commit('CHANGE_ACTIVITY_BLOCKING_ORDER', { id, blocking }));
      }
      return ActivityService.unblockOrderOfActivity(id)
        .then(() => commit('CHANGE_ACTIVITY_BLOCKING_ORDER', { id, blocking }));
    },
    removeActivity({ commit }, id) {
      ActivityService.deleteActivity(id)
        .then(() => {
          commit('REMOVE_ACTIVITY', id);
        })
        .catch(console.log);
    },
    reorderMaterials({ commit }, { materials, oldMaterialOrder, payload }) {
      commit('SET_MATERIALS_ON_CONTENT_LEVEL', { materials, parentId: payload.id });
      return new Promise((resolve, reject) => {
        ActivityService.changeOrder(payload)
          .then(resolve)
          .catch(() => reject(
            commit('SET_MATERIALS_ON_CONTENT_LEVEL', {
              materials: oldMaterialOrder,
              parentId: payload.id,
            }),
          ));
      });
    },
    selectContentId({ commit }, id = null) {
      commit('SELECT_CONTENT_ID', id);
    },
    resetMaterialList({ commit }) {
      commit('RESET_MATERIALS_LIST');
    },

    fetchContent({ commit }, { programId, courseId }) {
      return ActivityService.getActivitiesOfCourse({ programId, courseId })
        .then(({ data }) => {
          let flatActivities = [];
          if (data?.children) {
            // TODO techdolg - work with nested data
            flatActivities = makeFlatArrayFromNestedChildren(data.children);
          }
          commit('SET_CONTENT', { content: flatActivities, courseId });
          return data;
        })
        .catch(console.log);
    },
    setGroupIdOfLevelOne({ commit }, id) {
      return commit('SET_GROUP_ID_OF_LEVEL_ONE', id);
    },
    setGroupIdOfLevelTwo({ commit }, id) {
      return commit('SET_GROUP_ID_OF_LEVEL_TWO', id);
    },
  },

  mutations: {
    SET_CONTENT(state, { content, courseId }) {
      state.content = content;
      state.courseId = courseId;
    },
    SET_ONLY_CONTENT(state, content) {
      state.content = content;
    },
    SET_MATERIALS_ON_CONTENT_LEVEL(state, { materials, parentId }) {
      let index = -1;
      state.content = state.content.map((activity) => {
        if (activity.parent_id === parentId) {
          index += 1;
          return materials[index];
        }
        return activity;
      });
    },
    SET_GROUP_ID_OF_LEVEL_ONE(state, id) {
      state.groupIdOfLevelOne = id;
    },
    SET_GROUP_ID_OF_LEVEL_TWO(state, id) {
      state.groupIdOfLevelTwo = id;
    },
    ADD_MATERIAL(state, activity) {
      state.content.push(activity);
    },
    ADD_GROUP(state, group) {
      state.content.push(group);
    },
    UPDATE_ACTIVITY(state, activity) {
      state.content = state.content.map((m) => (m._id === activity._id ? activity : m));
    },
    CHANGE_ACTIVITY_BLOCKING_ORDER(state, { id, blocking }) {
      state.content = state.content.map((m) => {
        if (m._id === id) {
          m.is_blocking = blocking;
        }
        return m;
      });
    },
    REMOVE_ACTIVITY(state, id) {
      state.content = state.content.filter((a) => a._id !== id);
    },
    RESET_MATERIALS_LIST(state) {
      state.content = [];
    },
    SELECT_CONTENT_ID(state, id) {
      state.selectedContentId = id;
    },
  },

  getters: {
    // eslint-disable-next-line max-len
    selectedContent: ({ content, selectedContentId }) => content.find((m) => m?._id === selectedContentId) || null,
    selectedGroupOfLevelOne: ({ content, groupIdOfLevelOne }, { contentLevel }) => {
      if (contentLevel !== 0) {
        return content.find((activity) => activity._id === groupIdOfLevelOne);
      }
      return null;
    },
    selectedGroupOfLevelTwo: ({ content, groupIdOfLevelTwo }, { contentLevel }) => {
      if (contentLevel === 2) {
        return content.find((activity) => activity._id === groupIdOfLevelTwo);
      }
      return null;
    },
    contentLevel: ({ groupIdOfLevelOne, groupIdOfLevelTwo }) => {
      if (groupIdOfLevelTwo) {
        return 2;
      }
      if (groupIdOfLevelOne) {
        return 1;
      }
      return 0;
    },
    content: ({ content }) => content,
    contentOfTheGroupLength:
      ({ content }) => (groupId) => {
        const foundChildActivities = content.filter(
          (activity) => activity.parent_id === groupId && activity.type !== 'group',
        );
        return foundChildActivities.length;
      },
    groupIdOfLevelOne: ({ groupIdOfLevelOne }) => groupIdOfLevelOne,
    groupIdOfLevelTwo: ({ groupIdOfLevelTwo }) => groupIdOfLevelTwo,
    // eslint-disable-next-line max-len
    groupsOfCourse: (
      {
        courseId, content, groupIdOfLevelOne, groupIdOfLevelTwo,
      },
      { contentLevel },
    ) => {
      if (contentLevel === 0) {
        return content.filter(
          (activity) => activity.parent_id === courseId && activity.type === 'group',
        );
      }
      if (contentLevel === 1) {
        return content.filter(
          (activity) => activity.parent_id === groupIdOfLevelOne && activity.type === 'group',
        );
      }
      if (contentLevel === 2) {
        return content.filter(
          (activity) => activity.parent_id === groupIdOfLevelTwo && activity.type === 'group',
        );
      }
      return [];
    },
    // eslint-disable max-len
    materialsOfCourse: (
      {
        courseId, content, groupIdOfLevelOne, groupIdOfLevelTwo,
      },
      { contentLevel },
    ) => {
      if (contentLevel === 0) {
        return content.filter(
          (activity) => activity.parent_id === courseId && materialType.includes(activity.type),
        );
      }
      if (contentLevel === 1) {
        return content.filter(
          // eslint-disable-next-line max-len
          (activity) => activity.parent_id === groupIdOfLevelOne && materialType.includes(activity.type),
        );
      }
      if (contentLevel === 2) {
        return content.filter(
          // eslint-disable-next-line max-len
          (activity) => activity.parent_id === groupIdOfLevelTwo && materialType.includes(activity.type),
        );
      }
    },

    activitiesOfCurrentLevelContent: (
      {
        content, courseId, groupIdOfLevelOne, groupIdOfLevelTwo,
      },
      { contentLevel },
    ) => {
      if (contentLevel === 0) {
        return content.filter((activity) => activity.parent_id === courseId);
      }
      if (contentLevel === 1) {
        return content.filter(
          // eslint-disable-next-line max-len
          (activity) => activity.parent_id === groupIdOfLevelOne,
        );
      }
      if (contentLevel === 2) {
        return content.filter(
          // eslint-disable-next-line max-len
          (activity) => activity.parent_id === groupIdOfLevelTwo,
        );
      }
    },
  },
};
