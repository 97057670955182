import ActivityService from '@/services/activity.service';

export default {
  namespaced: true,

  state: {
    questions: [],
    loading: false,
  },

  actions: {
    fetchQuestions({ commit }, quizId) {
      return ActivityService.getActivitiesByParentId(quizId).then(({ data }) => commit('SET_QUESTIONS', data));
    },

    createQuestion(_, id) {
      return ActivityService.createActivityByType({
        type: 'question',
        id,
        meta: {
          is_multiple: false,
          answers: [
            {
              id: Math.random().toString(36).substr(2, 9),
              text: '',
              is_correct: true,
            },
          ],
        },
      }).then(({ data }) => data);
    },
    /**
     *
     * @param commit action context
     * @param getters action context
     * @param {Object} question have to pass question as answer is a part of question's meta
     * @returns {Promise<* | null>}
     */
    createAnswer({ commit }, question) {
      const clone = JSON.parse(JSON.stringify(question));
      clone.meta.answers.push({
        id: Math.random().toString(36).substr(2, 9),
        text: '',
        is_correct: question.meta.answers.length === 0,
      });

      return ActivityService.updateActivity(question._id, {
        meta: clone.meta,
        type: question.type,
      })
        .then(() => {
          commit('UPDATE_QUESTION', clone);
        })
        .catch((error) => {
          throw error;
        });
    },
    /**
     * UpdateQuestion - immediately mutates the state if the question has the _id, in the
     * absence - after the request is executed
     *
     * @param commit ctx
     * @param getters ctx
     * @param {string } id of question activity
     * @param {Object} question can be full Question object with all fields or only some keys of
     * question for instance '{description}'
     * @returns {Promise<* | null>}
     */
    updateQuestion({ commit, getters }, { id, question }) {
      commit('SET_LOADING', true);
      const previousState = [...getters.getQuestions];
      let isSentFullQuestionObject = false;
      if (question?._id) {
        isSentFullQuestionObject = true;
        commit('UPDATE_QUESTION', question);
      }

      const cloneQuestion = JSON.parse(JSON.stringify(question));

      if (!cloneQuestion.name) {
        delete cloneQuestion.name;
      }
      return ActivityService.updateActivity(id, cloneQuestion)
        .then(({ data }) => (!isSentFullQuestionObject ? commit('UPDATE_QUESTION', data) : null))
        .catch(() => {
          commit('SET_QUESTIONS', previousState);
        })
        .finally(() => commit('SET_LOADING', false));
    },

    updateAnswer({ commit, getters }, { question, answer }) {
      commit('SET_LOADING', true);
      const previousState = [...getters.getQuestions];
      const clone = JSON.parse(JSON.stringify(question));
      clone.meta.answers = clone.meta.answers.map((a) => (a.id === answer.id ? answer : a));
      commit('UPDATE_QUESTION', clone);
      return ActivityService.updateActivity(question._id, {
        meta: clone.meta,
        type: question.type,
      })
        .catch((error) => {
          commit('SET_QUESTIONS', previousState);
          console.log(error);
        })
        .finally(() => commit('SET_LOADING', false));
    },
    removeQuestion({ commit }, id) {
      return ActivityService.deleteActivity(id)
        .then(() => commit('REMOVE_QUESTION', id))
        .catch(console.log);
    },

    removeAnswer({ commit, getters }, { question, answer }) {
      const previousState = [...getters.getQuestions];
      const clone = JSON.parse(JSON.stringify(question));
      clone.meta.answers = clone.meta.answers.filter((a) => a.id !== answer.id);
      const numberOfSelectedAnswers = clone.meta.answers.filter((a) => a.is_correct).length;
      if (numberOfSelectedAnswers === 0 && clone.meta.answers.length) {
        clone.meta.answers[0].is_correct = true;
      }
      commit('UPDATE_QUESTION', clone);

      return ActivityService.updateActivity(question._id, {
        meta: clone.meta,
        type: question.type,
      }).catch((error) => {
        commit('SET_QUESTIONS', previousState);
        console.log(error);
      });
    },

    /**
     * Change the order of questions to the new one and restore the old one in case of failure
     *
     * @param commit action context
     * @param {Array} questionsByNewOrder questions placement in a new order
     * @param {Array} questionsByOldOrder questions placement in a old order
     * @param {{ id: string, oldIds: Array, newIds: Array }} payload payload for api
     * @return  { Promise } axios response
     *
     */
    reorderQuestions({ commit }, { questionsByNewOrder, questionsByOldOrder, payload }) {
      commit('SET_QUESTIONS', questionsByNewOrder);
      return ActivityService.changeOrder(payload).catch(() => commit('SET_QUESTIONS', questionsByOldOrder));
    },

    resetQuestions({ commit }) {
      commit('RESET_QUESTIONS');
    },
  },

  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions;
    },
    UPDATE_QUESTION(state, question) {
      state.questions = state.questions.map((q) => (q._id === question._id ? question : q));
    },
    REMOVE_QUESTION(state, id) {
      state.questions = state.questions.filter((q) => q._id !== id);
    },
    RESET_QUESTIONS(state) {
      state.questions = [];
    },
    SET_LOADING(state, bool) {
      state.loading = bool;
    },
  },

  getters: {
    getQuestions: ({ questions }) => questions,
    loading: ({ loading }) => loading,
  },
};
