export default {
  namespaced: true,

  state: {
    isLoaderShown: false,
  },

  mutations: {
    ACTIVATE_LOADER(state, isActive) {
      state.isLoaderShown = isActive;
    },
  },

  getters: {
    getIsLoaderShown: ({ isLoaderShown }) => isLoaderShown,
  },
};
