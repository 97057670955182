<template>
  <div class="dropdown-with-checkboxes">
    <b-form-group
      :id="id"
      :label-for="id"
    >
      <b-input-group class="cursor-pointer" @click="toggleDropdown">
        <b-form-input
          class="cursor-pointer"
          :id="id"
          :ref="id"
          :disabled="true"
          v-model="selected.text"
          :placeholder="$t('placeholders.chooseTopic')"
          @blur="isFocused = false"
          @focus="isFocused = true"
          autocomplete="off"
        >
        </b-form-input>
        <b-input-group-append>
          <b-icon-chevron-down :class="{ rotate180: showDropdown }" />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div class="dropdown-with-checkboxes__dropdown" :class="{ show: showDropdown }">
      <div class="dropdown-checkboxes">
        <div
          v-for="item in options"
          :key="item.value"
          class="dropdown-checkboxes__item"
          @click="selectItem(item)"
        >
          <div class="dropdown-checkboxes__label">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LeraSelector',
  props: {
    id: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isFocused: false,
    showDropdown: false,
  }),
  methods: {
    selectItem(item) {
      this.$emit('change', item);
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>
