<script>
import { mapActions, mapGetters } from 'vuex';
import MaterialService from '@/services/material.service';

// TODO rewrite toast, make it stupid without logic
//  to update or create material, just apply callback

export default {
  name: 'LmsToaster',
  render: (h) => h('div'),
  computed: {
    ...mapGetters({ toaster: 'toaster/getToaster' }),
  },
  methods: {
    ...mapActions('courseContentTeacher', ['createActivity', 'updateActivity']),
    makeLoadingToast(payload) {
      if (payload?.retry === 'createActivity' || !payload?.file) {
        return this.upload(payload);
      }

      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__first-block' }, [
          h('div', { class: 'header__icon' }, [
            h('img', {
              attrs: {
                src: require('@/assets/images/svg/file-loading-icon.svg'),
              },
            }),
          ]),
          h('div', { class: 'header_tittle' }, this.$t('supportText.uploadingMaterial')),
        ]),
        h('img', {
          on: { click: () => this.$bvToast.hide(payload.id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = h('div', { class: 'body-toast__wrapper' }, [
        h('div', { class: 'body-toast__icon' }, ''),
        h('div', { class: ['body__message'] }, [
          h('div', { class: 'message__title' }, [`${payload.meta.fileName}`]),
          h('div', { class: 'body__message__subtitle' }, [
            `${this.$t('supportText.fileSize')} ${payload.meta.fileSize}`,
          ]),
        ]),
      ]);

      let toasterBottomClass = 'b-toaster-bottom-right';
      if (window.innerWidth <= 768) {
        toasterBottomClass = 'b-toaster-bottom-center';
      }
      this.$bvToast.toast([vNodesMsg], {
        id: payload.id,
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        title: [vNodesTitle],
        noAutoHide: true,
        toaster: `${toasterBottomClass} lms-toast`,
        variant: 'info',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
      setTimeout(() => this.upload(payload), 250);
    },
    makeSuccessfullToast({
      id, meta, title, body, autoHideDelay,
    }) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__icon' }, [
          h('img', {
            attrs: {
              src: require('@/assets/images/svg/successful-icon.svg'),
            },
          }),
        ]),
        h(
          'div',
          { class: 'header__title' },
          meta ? this.$t('supportText.materialUploadedSuccessfully') : title,
        ),
        h('img', {
          on: { click: () => this.$bvToast.hide(id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = meta
        ? h('div', { class: 'body-toast__wrapper' }, [
          h('div', { class: 'body-toast__icon' }, ''),
          h('div', { class: ['body-toast__message body-toast__title', 'message'] }, [
            h('div', { class: 'message__title' }, [`${meta.fileName}`]),
            h('div', { class: 'message__subtitle' }, [
              `${this.$t('supportText.fileSize')} ${meta.fileSize}`,
            ]),
          ]),
        ])
        : h('div', { class: 'body-toast__wrapper' }, [
          h('div', { class: 'body-toast__icon' }, ' '),
          h('div', { class: ['body-toast__message', 'message'] }, [
            h('div', { class: 'message__title' }, body),
          ]),
        ]) || null;

      let toasterBottomClass = 'b-toaster-bottom-right';
      if (window.innerWidth <= 768) {
        toasterBottomClass = 'b-toaster-bottom-center';
      }
      this.$bvToast.toast([vNodesMsg], {
        id,
        title: [vNodesTitle],
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        autoHideDelay,
        toaster: `${toasterBottomClass} lms-toast`,
        variant: 'success',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },
    makeRetryToast(payload) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__icon' }, [
          h('img', {
            attrs: {
              src: require('@/assets/images/svg/warning-icon.svg'),
            },
          }),
        ]),
        h(
          'div',
          { class: 'header__title' },
          payload?.toastRetryTitle
            ? payload.toastRetryTitle
            : this.$t('errorMessages.failedUploadFileToServer'),
        ),
        h('img', {
          on: { click: () => this.$bvToast.hide(payload.id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = h('div', { class: 'body-toast__wrapper' }, [
        h('div', { class: 'body-toast__icon' }, ''),
        h('div', { class: ['body-toast__message', 'message'] }, [
          h('div', { class: 'message__title' }, this.$t('errorMessages.errorOccurredTryingToFix')),
          h(
            'div',
            {
              class: 'message__action',
              on: {
                click: () => {
                  this.$bvToast.hide(payload.id);
                  this.makeLoadingToast(payload);
                },
              },
            },
            this.$t('errorMessages.tryAgain'),
          ),
        ]),
      ]);

      let toasterBottomClass = 'b-toaster-bottom-right';
      if (window.innerWidth <= 768) {
        toasterBottomClass = 'b-toaster-bottom-center';
      }
      this.$bvToast.toast([vNodesMsg], {
        id: payload.id,
        title: [vNodesTitle],
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        autoHideDelay: 30000,
        toaster: `${toasterBottomClass} lms-toast`,
        variant: 'warning',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },
    makeDangerToast(id, title, body) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__icon' }, [
          h('img', {
            attrs: {
              src: require('@/assets/images/svg/danger-icon.svg'),
            },
          }),
        ]),
        h('div', { class: 'header__title' }, title),
        h('img', {
          on: { click: () => this.$bvToast.hide(id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/white-close-icon.svg') },
        }),
      ]);
      const vNodesMsg = h('div', { class: 'body-toast__wrapper' }, [
        h('div', { class: 'body-toast__icon' }, ''),
        h('div', { class: ['body-toast__message', 'message'] }, [
          h('div', { class: 'message__title' }, body),
        ]),
      ]);

      let toasterBottomClass = 'b-toaster-bottom-right';
      if (window.innerWidth <= 768) {
        toasterBottomClass = 'b-toaster-bottom-center';
      }
      this.$bvToast.toast([vNodesMsg], {
        id,
        title: [vNodesTitle],
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        autoHideDelay: 30000,
        toaster: `${toasterBottomClass} lms-toast`,
        variant: 'danger',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },
    makeWarningToast(id, title, body, button, autoHideDelay = 30000) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__icon' }, [
          h('img', {
            attrs: {
              src: require('@/assets/images/svg/warning-icon.svg'),
            },
          }),
        ]),
        h('div', { class: 'header__title' }, title),
        h('img', {
          on: { click: () => this.$bvToast.hide(id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = body
        ? h('div', { class: 'body-toast__wrapper' }, [
          h('div', { class: 'body-toast__icon' }, ''),
          h('div', { class: ['body-toast__message', 'message'] }, [
            h('div', { class: 'message__title' }, body),
            button
              ? h(
                'div',
                {
                  class: 'message__action',
                  on: {
                    click: () => {
                      this.$bvToast.hide(id);
                      button.action();
                    },
                  },
                },
                button.text,
              )
              : null,
          ]),
        ])
        : null;

      let toasterBottomClass = 'b-toaster-bottom-right';
      if (window.innerWidth <= 768) {
        toasterBottomClass = 'b-toaster-bottom-center';
      }
      this.$bvToast.toast([vNodesMsg], {
        id,
        title: [vNodesTitle],
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        autoHideDelay,
        toaster: `${toasterBottomClass} lms-toast`,
        variant: 'warning',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },

    async upload(payload) {
      if (payload?.retry === 'createActivity' || !payload?.file) {
        await this.createAct(payload);
        this.makeSuccessfullToast(payload);
        return;
      }

      if (payload?.activityType === 'task') {
        MaterialService.uploadFile(payload.file)
          .then(async ({ data: { url } }) => { this.appendLinkToActivity(url, payload); })
          .catch((e) => {
            if (e.message !== 'fail on creation activity') {
              console.log(e);
              this.$bvToast.hide(payload.id);
              this.makeRetryToast(payload);
            }
          });
      } else {
        MaterialService.uploadMaterial(payload.file)
          .then(async ({ data: { url } }) => { this.appendLinkToActivity(url, payload); })
          .catch((e) => {
            if (e.message !== 'fail on creation activity') {
              console.log(e);
              this.$bvToast.hide(payload.id);
              this.makeRetryToast(payload);
            }
          });
      }
    },
    async appendLinkToActivity(url, payload) {
      this.$bvToast.hide(payload.id);
      const extendPayload = { ...payload };
      extendPayload.meta = {
        ...payload.meta,
        url,
      };
      if (payload?.isUpdateActivity) {
        await this.updateActivity({
          id: payload.activityId,
          activity: {
            type: payload.type,
            name: payload.name,
            meta: extendPayload.meta,
          },
        });
      } else {
        await this.createAct(extendPayload);
      }

      this.makeSuccessfullToast(payload);
    },
    createAct(payload) {
      return this.createActivity(payload).catch(() => {
        this.$bvToast.hide(payload.id);
        // eslint-disable-next-line no-param-reassign
        payload.toastRetryTitle = this.$t('errorMessages.somethingWentWrong');
        this.makeRetryToast({
          ...payload,
          retry: 'createActivity',
        });
        throw new Error('fail on creation activity');
      });
    },
  },
  watch: {
    toaster: {
      handler(config) {
        const id = Date.now().toString();
        if (config.type === 'upload-file') {
          this.makeLoadingToast({ id, ...config.toast });
        }
        if (config.type === 'toast-danger') {
          const { title, body } = config.toast;
          this.makeDangerToast(id, title, body);
        }
        if (config.type === 'toast-warning') {
          const { title, body, button } = config.toast;
          const autoHideDelay = config?.autoHideDelay ? config.autoHideDelay : 30000;
          this.makeWarningToast(id, title, body, button, autoHideDelay);
        }
        if (config.type === 'toast-success') {
          const { title, body } = config.toast;
          this.makeSuccessfullToast({
            id,
            title,
            body,
            meta: null,
            autoHideDelay: config?.autoHideDelay ? config.autoHideDelay : 30000,
          });
        }
      },
    },
  },
};
</script>
