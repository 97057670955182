import Vue from 'vue';

export default Vue.directive('click-outside', {
  bind(el, binding, vNode) {
    // eslint-disable-next-line no-use-before-define
    if (!validateCallback(binding)) return;

    // Define Handler and cache it on the element
    const handler = (e) => {
      if (!vNode.context) return;

      // some components may have related popup item,
      // on which we shall prevent the click outside event handler.
      const elements = e.path || (e.composedPath && e.composedPath());
      // eslint-disable-next-line no-unused-expressions
      elements && elements.length > 0 && elements.unshift(e.target);

      // eslint-disable-next-line no-use-before-define
      if (el.contains(e.target) || hasToBeIgnored(vNode.context.ignoredElements, elements)) return;

      el.__vueClickOutside__.callback(e);
    };

    // add Event Listeners
    // eslint-disable-next-line no-param-reassign
    el.__vueClickOutside__ = {
      handler,
      callback: binding.value,
    };
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    // eslint-disable-next-line no-unused-expressions,no-use-before-define
    !isServer(vNode) && document.addEventListener(clickHandler, handler);
  },

  update(el, binding) {
    // eslint-disable-next-line no-use-before-define,no-param-reassign
    if (validateCallback(binding)) el.__vueClickOutside__.callback = binding.value;
  },

  unbind(el, binding, vNode) {
    // Remove Event Listeners
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    // eslint-disable-next-line no-unused-expressions,no-use-before-define
    !isServer(vNode)
      && el.__vueClickOutside__
      && document.removeEventListener(clickHandler, el.__vueClickOutside__.handler);
    // eslint-disable-next-line no-param-reassign
    delete el.__vueClickOutside__;
  },
});

const validateCallback = (binding) => {
  if (typeof binding.value !== 'function') {
    console.warn('[ClickOutside:] Provided expression', binding.expression, 'is not a function.');
    return false;
  }

  return true;
};

const hasToBeIgnored = (ignoredElements, elements) => {
  if (!ignoredElements || !elements) return false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < elements.length; i++) {
    try {
      if (ignoredElements.contains(elements[i])) {
        return true;
      }

      if (elements[i].contains(ignoredElements)) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return false;
};

function isServer(vNode) {
  return typeof vNode.componentInstance !== 'undefined' && vNode.componentInstance.$isServer;
}
