import axios from '@/http/index';
import CheckAccess from '@/utils/checkAccessDecorator';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

const payload = {
  type: 'program',
  name: '',
  description: '',
};

const defaultQuery = {
  page: 1,
  per_page: 12,
  managed: true,
};

const getAll = '?page=1&per_page=999';

class ActivityService {
  @CheckAccess
  createProgram = () => axios.post('teacher/activity/', payload);

  // used only in list of courses to create course
  @CheckAccess
  createCourse = () => axios.post('teacher/activity/', {
    ...payload,
    type: 'course',
  });

  @CheckAccess
  createActivityByType = ({
    type,
    id,
    meta = null,
    name = '',
    description = '',
    max_score = 0,
  }) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.post(`teacher/${programId}/`, {
        type,
        parent_id: id,
        meta,
        name,
        description,
        max_score,
      });
    }
    return axios.post('teacher/activity/', {
      type,
      parent_id: id,
      meta,
      name,
      description,
      max_score,
    });
  };

  @CheckAccess
  getPrograms = (query = defaultQuery) => axios.get(
    `teacher/programs?page=${query.page}&per_page=${query.per_page}&authored=${query.authored}&managed=${query.managed}`,
  );

  @CheckAccess
  getCourses = () => axios.get(`teacher/courses${getAll}&authored=true`);

  @CheckAccess
  getActivitiesByParentId = (id) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.get(`teacher/${programId}/${id}/children${getAll}`);
    }
    return axios.get(`teacher/activity/${id}/children${getAll}`);
  };

  @CheckAccess
  getActivityById = (id) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.get(`teacher/${programId}/${id}`);
    }
    return axios.get(`teacher/activity/${id}`);
  };

  @CheckAccess
  deleteActivity = async (id) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.delete(`teacher/${programId}/${id}`);
    }
    return axios.delete(`teacher/activity/${id}`);
  };

  @CheckAccess
  getTotalCount = (query) => axios.get(
    `teacher/total_count?type=${query.type}&authored=${query.authored}&managed=${query.managed}`,
  );

  @CheckAccess
  updateActivity = async (id, data) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.patch(`teacher/${programId}/${id}`, data);
    }
    return axios.patch(`teacher/activity/${id}`, data);
  };

  /**
   * Executing promises one by one to add activity parent
   *
   * Promise.resolve() as initial value of reduce function is needed to use next promise chaining
   */
  @CheckAccess
  addActivityParent = async ({ programId, ids, parentId }) => {
    // eslint-disable-next-line max-len
    const callBackQueue = (callBacks) => callBacks.reduce((promise, callback) => promise.then(callback), Promise.resolve());
    const callBacksArray = ids.map(
      (id) => () => axios.patch(`teacher/${programId}/${id}/structure/append_parent`, { parent_id: parentId }),
    );
    return callBackQueue(callBacksArray);
  };

  @CheckAccess
  removeActivityParent = async ({
    programId,
    id,
    parentId,
  }) => axios.patch(`teacher/${programId}/${id}/structure/remove_parent`, { parent_id: parentId });

  @CheckAccess
  appendStudent = ({ id, token }) => axios.post(`join/${id}/student`, { token });

  @CheckAccess
  changeOrder = async ({ id, oldIds, newIds }) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }

    return axios.post(`teacher/${programId}/${id}/structure/reorder`, {
      old_ids: oldIds,
      new_ids: newIds,
    });
  };

  @CheckAccess
  banMultipleStudents = ({ programId, id, students }) => axios.patch(`teacher/${programId}/${id}/students/ban_students`, { students_ids: students });

  @CheckAccess
  unbanMultipleStudents = ({ programId, id, students }) => axios
    .patch(`teacher/${programId}/${id}/students/unban_students`, { students_ids: students })
    .then(({ data }) => data);

  @CheckAccess
  getActivityStudentsStatistics = ({ programId, id }) => axios.get(`teacher/${programId}/${id}/statistics`);

  /**
   * @param query.sortKey string,
   * @param query.sortDirection string,
   * @param query.page number,
   * @param query.perPage number,
   */
  @CheckAccess
  getStudents = (query) => axios.get(
    `teacher/manage/students?sort_key=${
        query?.sortKey ? query?.sortKey : 'username'
    }&sort_direction=${query?.sortDirection ? query?.sortDirection : 'descending'}&page=${
        query?.page ? query?.page : 1
    }&per_page=${query?.perPage ? query?.perPage : 5}`,
  );

  @CheckAccess
  banStudentsInAllPrograms = (students_ids) => axios.post('teacher/manage/students/ban', { students_ids });

  @CheckAccess
  unbanStudentsInAllPrograms = (students_ids) => axios.post('teacher/manage/students/unban', { students_ids }).then(({ data }) => data);

  @CheckAccess
  getActivitiesOfCourse = ({ courseId }) => {
    const { programId } = router.currentRoute.params;
    if (programId && programId !== 'self') {
      return axios.get(`/teacher/${programId}/${courseId}/children_recursively`);
    }
    return axios.get(`/teacher/activity/${courseId}/children_recursively`);
  };

  @CheckAccess
  getProgramToken = (id) => axios.get(`teacher/${id}/${id}/managers/token`);

  @CheckAccess
  getProgramSubscribeToken = (id) => axios.get(`teacher/${id}/${id}/students/token`);

  @CheckAccess
  getActivityStatisticsFile = ({ programId, id, locale }) => axios.get(`teacher/${programId}/${id}/statistics/file`, {
    responseType: 'blob',
    headers: { locale },
  });

  @CheckAccess
  getActivityStatisticsFileWithChildren = ({ programId, id, locale }) => axios.get(`teacher/${programId}/${id}/statistics/file_with_children`, {
    responseType: 'blob',
    headers: { locale },
  });

  @CheckAccess
  getActivityStatisticsFileWithChildrenRecursively = ({ programId, id, locale }) => axios.get(`teacher/${programId}/${id}/statistics/file_with_children_recursively`, {
    responseType: 'blob',
    headers: { locale },
  });

  @CheckAccess
  getActivityAnswersFile = ({ programId, id, locale }) => axios.get(`teacher/${programId}/${id}/statistics/task_answers_file`, {
    responseType: 'blob',
    headers: { locale },
  });

  @CheckAccess
  appendManager = ({ programId, token }) => axios.post(`join/${programId}/manager`, { token });

  @CheckAccess
  getProgramManagers = ({ programId, id }) => axios.get(`teacher/${programId}/${id}/managers`);

  @CheckAccess
  banManagersInProgram = ({ programId, id, managers_ids }) => axios.patch(`teacher/${programId}/${id}/managers/ban`, { managers_ids });

  @CheckAccess
  unbanManagersInProgram = ({ programId, id, managers_ids }) => axios.patch(`teacher/${programId}/${id}/managers/unban`, { managers_ids });

  @CheckAccess
  getCertificate = (id) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.get(`teacher/${programId}/${id}/certificate`);
  };

  @CheckAccess
  createCertificate = ({ id, config }) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.post(`/teacher/${programId}/${id}/certificate`, { config });
  };

  @CheckAccess
  updateCertificate = ({ id, config }) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.patch(`/teacher/${programId}/${id}/certificate`, { config });
  };

  @CheckAccess
  deleteCertificate = (id) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.delete(`/teacher/${programId}/${id}/certificate`);
  };

  @CheckAccess
  getTaskAnswersFile = (ids, locale) => axios.post('teacher/task/chat/tasks_answers_file',
    { task_ids: ids },
    {
      headers: { locale },
      responseType: 'blob',
    });

  @CheckAccess
  blockOrderOfActivity = async (id) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.patch(`teacher/${programId}/${id}/structure/block_order`);
  };

  @CheckAccess
  unblockOrderOfActivity = async (id) => {
    let { programId } = router.currentRoute.params;
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.patch(`teacher/${programId}/${id}/structure/unlock_order`);
  };
}

export default new ActivityService();
