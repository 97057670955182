<template>
  <div class="splash-screen" v-if="isShown">
    <p>{{ mode }}</p>
    <div class="splash-screen__loader">
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'SplashScreen',
  data() {
    return {
      isShown: false,
      mode: this.$t('supportText.switchingToStudent'),
    };
  },
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({
      isLoaderActive: 'loader/getIsLoaderShown',
      isTeacher: 'auth/isModeTeacher',
    }),
  },
  watch: {
    isLoaderActive: {
      handler(isActive) {
        if (isActive) {
          if (this.isTeacher) {
            this.mode = this.$t('supportText.switchingToStudent');
          } else {
            this.mode = this.$t('supportText.switchingToTeacher');
          }
          this.isShown = true;
          setTimeout(() => (this.isShown = false), 3600);
        }
      },
    },
  },
};
</script>
