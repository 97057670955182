import axios from '@/http/index';
import store from '@/store';
import { redirectPageForUnauthorized } from '@/utils/constants';
import { destroyTokens, saveTokens } from '@/utils/helpers';

class AuthService {
  register = (registerInput) => {
    delete axios.defaults.headers.common.Authorization;
    return axios
      .post('auth/signup-bearer', registerInput)
      .then(({ data: tokens }) => {
        saveTokens(tokens);
        axios.defaults.headers.common.Authorization = tokens.access_token;
      });
  };

  // login = (loginInput) => axios.post('auth/signin', loginInput);
  login = (loginInput) => {
    delete axios.defaults.headers.common.Authorization;
    return axios.post('auth/signin-bearer', loginInput).then(({ data: tokens }) => {
      saveTokens(tokens);
    });
  };

  logout = () => {
    store.commit('auth/SET_AUTHENTICATED', { isAuthenticated: false });
    destroyTokens();
    return false;
  };

  forgotPassword = ({ email, locale }) => axios.post('auth/forgot_password', { email }, { headers: { locale } });

  resetPassword = ({ password, token }) => axios.patch('auth/reset_password', { password, token });

  confirmEmail = () => axios.patch('auth/confirm_email');

  sendConfirmEmail = (locale) => axios.post('auth/send_confirm_email', null, { headers: { locale } });

  checkAuth = async (next) => {
    if (store.getters['auth/isAuthenticated']) {
      next();
    } else {
      try {
        const { data: User } = await axios.get('auth/whoami');

        if (User) {
          store.commit('auth/SET_AUTHENTICATED', {
            isAuthenticated: true,
            user: User,
          });
          next();
        } else {
          next(redirectPageForUnauthorized);
        }
      } catch (err) {
        next(redirectPageForUnauthorized);
      }
    }
  };

  isAuth = async () => {
    if (store.getters['auth/isAuthenticated']) {
      return true;
    }
    return axios
      .get('auth/whoami')
      .then(({ data: user }) => {
        store.commit('auth/SET_AUTHENTICATED', {
          isAuthenticated: !!user,
          user,
        });
        return true;
      })
      .catch(() => {
        store.commit('auth/SET_AUTHENTICATED', {
          isAuthenticated: false,
        });
        return false;
      });
  };

  whoAmI = () => axios
    .get('auth/whoami')
    .then(({ data: user }) => {
      store.commit('auth/SET_AUTHENTICATED', {
        isAuthenticated: !!user,
        user,
      });
      return true;
    })
    .catch(console.log);
}

export default new AuthService();
