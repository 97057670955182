export const pdf = 'pdf';
export const docx = 'docx';
export const pptx = 'pptx';
export const zip = 'zip';

export const accessToken = 'access_token';
export const refreshToken = 'refresh_token';

export const redirectPageForUnauthorized = '/auth';
export const redirect403 = '/no-access';

export const done = {
  value: 'done',
  label: 'label.passed',
};

export const unavailable = {
  value: 'unavailable',
  label: 'label.notAvailable',
};

export const doing = {
  value: 'doing',
  label: 'label.inProgress',
};

export const notStarted = {
  value: 'not-started',
  label: 'label.notStarted',
};

export const blocked = {
  value: 'blocked',
  label: 'label.blocked',
};

export const rejected = {
  value: 'rejected',
  label: 'label.rejected',
};

export const abc = 'АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЭэЮюЯяЬьЫыЪъAaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpRrSsTtUuVvWwXxYyZz!?./01234567890';

export const fontFamilies = [
  'Alegreya',
  'Alegreya Sans',
  'Alegreya Sans SC',
  'Alegreya SC',
  'Alice',
  'Alumni Sans',
  'Andika',
  'Arimo',
  'Arsenal',
  'Bitter',
  'Bona Nova',
  'Brygada 1918',
  // 'Calibri',
  'Caveat',
  'Commissioner',
  'Cormorant',
  'Cormorant Garamond',
  'Cormorant Infant',
  'Cormorant SC',
  'Cormorant Unicase',
  'Cuprum',
  'Didact Gothic',
  'EB Garamond',
  'Exo 2',
  'Fira Sans',
  'Fira Sans Condensed',
  'Fira Sans Extra Condensed',
  'Gabriela',
  'IBM Plex Sans',
  'IBM Plex Sans Arabic',
  'IBM Plex Sans Condensed',
  'IBM Plex Sans Devanagari',
  'IBM Plex Sans Hebrew',
  'IBM Plex Sans Thai',
  'IBM Plex Sans Thai Looped',
  'IBM Plex Serif',
  'Inter',
  'Istok Web',
  'Jura',
  'Kurale',
  'Literata',
  'Lora',
  'M PLUS 1p',
  'M PLUS Rounded 1c',
  'Manrope',
  'Merriweather',
  'Merriweather Sans',
  'Montserrat',
  'Montserrat Alternates',
  'Mulish',
  'Murecho',
  'Noto Sans',
  'Noto Sans Display',
  'Noto Serif',
  'Noto Serif Display',
  'Nunito',
  'Nunito Sans',
  'Old Standard TT',
  'Open Sans',
  'Oranienbaum',
  'Oswald',
  'Overpass',
  'Pacifico',
  'Pangolin',
  'Philosopher',
  'Piazzolla',
  'Play',
  'Plus Jakarta Sans',
  'Podkova',
  'Prata',
  'PT Sans',
  'PT Sans Caption',
  'PT Sans Narrow',
  'PT Serif',
  'PT Serif Caption',
  'Raleway',
  'Recursive',
  'Roboto',
  'Roboto Condensed',
  'Roboto Slab',
  'Rubik',
  'Scada',
  'Source Sans 3',
  'Source Sans Pro',
  'Source Serif 4',
  'Source Serif Pro',
  'STIX Two Text',
  'Tinos',
  'Ubuntu',
  'Ubuntu Condensed',
  'Vollkorn',
  'Vollkorn SC',
];

export const certificateTutorial = 'certificate-tutorial';

export const planetImagesUrl = 'https://storage.yandexcloud.net/cg-lera-assets/themes/main/';

export const materialType = ['task', 'material', 'quiz', 'video'];
